<template>
  <v-row>
    <v-col cols="12">
      <div class="pt-7">
        <v-autocomplete
          :label="`Search or add a new ${specie.name} breed`"
          :search-input.sync="searchKey"
          :menu-props="{ zIndex: 200 }"
          :hide-no-data="hideNoData"
          :loading="breeds.loading"
          :items="searchResults"
          ref="breedSearch"
          item-text="name"
          item-value="id"
          hide-selected
          hide-details
          no-filter
          clearable
          outlined
          flat
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <div class="py-2 text-center">
                  <div class="mb-8">
                    <span>
                      No results matching "
                      <strong class="red--text"> {{ searchKey }} </strong>".
                    </span>
                  </div>
                  <v-btn class="text-capitalize px-5" color="primary">
                    <v-icon class="mr-4"> {{ icons.plus }} </v-icon>
                    <span class="text-capitalize" @click="addItem(searchKey)">
                      Add as a new breed
                    </span>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{
                item.name
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action class="d-flex">
              <div>
                <v-btn
                  @click="removeItem(item)"
                  color="error"
                  small
                  icon
                  dark
                  fab
                >
                  <v-icon>{{ icons.delete }}</v-icon>
                </v-btn>

                <v-btn
                  @click="editItem(item)"
                  color="success"
                  small
                  icon
                  dark
                  fab
                >
                  <v-icon>{{ icons.edit }}</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </div>

      <div class="pt-5">
        <template v-for="(item, itemIndex) in limitedBreeds">
          <v-chip class="my-2 mx-1 px-5 text-capitalize" :key="itemIndex">
            <span class="mr-2">{{ item.name }}</span>
          </v-chip>
        </template>
        <v-chip
          v-if="breeds.list.length > 10"
          @click="limit = !limit"
          class="mx-2 font-weight-bold primary--text"
        >
          {{ limitText }}
        </v-chip>
      </div>
    </v-col>

    <ConfirmModal v-model="confirmDialog" title="Remove Type">
      <template #message>
        Do you want to remove
        <span v-if="selectedType.name" class="font-weight-bold red--text">
          {{
            selectedType.name.replace(/(^|\s)\S/g, function (t) {
              return t.toUpperCase()
            })
          }}
        </span>
        ?
      </template>

      <v-btn :disabled="deleting" @click="cancelRemove()" text> Cancel </v-btn>
      <v-btn color="warning" :loading="deleting" @click="confirmRemove()" text>
        Delete
      </v-btn>
    </ConfirmModal>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar.show = false">OK</v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import Breed from '../models/Breed'

import { mdiClose, mdiPencil, mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import { take, isEmpty, debounce } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  name: 'Breeds',

  components: {
    ConfirmModal,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },

    specie: {
      required: true,
    },
  },

  data() {
    return {
      limit: true,
      deleting: false,
      confirmDialog: false,
      searchKey: null,
      searchResults: [],

      snackbar: {
        show: false,
        message: null,
        color: '',
      },

      breeds: {
        list: [],
        meta: {
          total: 0,
        },
        limit: 10,
        loading: false,
      },

      icons: {
        plus: mdiPlus,
        edit: mdiPencil,
        close: mdiClose,
        delete: mdiTrashCanOutline,
      },

      selectedType: {
        id: null,
        name: null,
      },
    }
  },

  created() {
    this.fetchBreeds()
  },

  computed: {
    limitText() {
      return this.limit
        ? `Show all ${this.breeds.list.length - 10}+`
        : 'Show less'
    },
    hasSearch() {
      return !isEmpty(this.searchKey)
    },

    hasSearchResults() {
      return !isEmpty(this.searchResults)
    },

    isEmptySearch() {
      return isEmpty(this.searchKey)
    },

    hideNoData() {
      return !this.hasSearch || this.hasSearchResults
    },

    limitedBreeds() {
      return this.limit ? take(this.breeds.list, 10) : this.breeds.list
    },
  },

  methods: {
    ...mapMutations({
      updateSpeciesCount: 'species/updateSpeciesCount',
    }),

    async fetchBreeds() {
      if (this.breeds.loading) return

      this.breeds.loading = true

      const query = Breed.where({
        species_id: this.specie.id,
        search: this.searchKey,
      })

      const { data, meta } = await query.get()

      if (this.isEmptySearch) {
        this.breeds.list = data
        this.breeds.meta = meta
      } else {
        this.searchResults = data
      }

      this.breeds.loading = false
    },

    searchCategories: debounce(function () {
      this.fetchBreeds()
    }, 400),

    removeItem(item) {
      this.confirmDialog = true
      this.selectedType = item
    },

    cancelRemove() {
      this.selectedType = {
        id: null,
        name: null,
      }

      this.confirmDialog = false
      this.$refs.breedSearch.reset()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    async confirmRemove() {
      this.deleting = true
      this.$refs.breedSearch.reset()

      try {
        await this.selectedType.delete()
        this.deleting = false
        this.confirmDialog = false

        this.updateSpeciesCount({
          id: this.selectedType.species_id,
          method: 'remove',
        })

        this.showSnackbar('Breed successfully removed!', 'success')
      } catch (e) {
        this.deleting = false
        this.confirmDialog = false
        this.showSnackbar(e.response.data.message, 'error')
      }
    },

    addItem(searchBreed) {
      this.$refs.breedSearch.reset()
      this.searchKey = null
      this.$emit('add-item', searchBreed)
    },

    editItem(item) {
      this.$emit('edit-item', item)
    },
  },

  watch: {
    searchKey(value) {
      this.searchCategories()
    },

    'breeds.list'() {
      this.searchResults = this.breeds.list
    },
  },
}
</script>
