<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold mr-4">
        Species and Breeds
      </v-toolbar-title>
    </v-app-bar>

    <v-divider class="mt-3" />

    <div class="mt-12 mx-4 pa-xs-4 mx-sm-auto">
      <v-row>
        <v-col class="d-flex" cols="12" md="8">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showAddBreedModal">
            <v-icon class="mr-1"> {{ icons.plus }} </v-icon>
            New Specie
          </v-btn>
        </v-col>
      </v-row>

      <Species @edit-species="onEditSpecies($event)" />
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar.show = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="speciesModal.isShowModal" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5">
          {{ speciesModal.title }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                v-model="speciesForm.name"
                label="Species Name"
                :error-messages="speciesForm.$getError('name')"
                :hide-details="!speciesForm.$hasError('name')"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4 mx-2">
          <v-spacer />

          <v-btn
            color="error"
            :disabled="speciesForm.$busy"
            @click="cancelEdit()"
            text
          >
            Cancel
          </v-btn>

          <v-btn
            :loading="speciesForm.$busy"
            color="primary"
            @click="
              speciesModal.isEdit
                ? updateNewSpecieRecord()
                : addNewSpeciesRecord()
            "
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import Species from '../../../components/Species'
import Form from '@/utils/form'

import { mapActions, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'SpeciesAndBreeds',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    Species,
  },

  data() {
    return {
      speciesModal: {
        title: '',
        isShowModal: false,
        isEdit: false,
      },

      editBreedModal: false,

      icons: {
        plus: mdiPlus,
      },

      speciesForm: new Form({
        id: null,
        name: null,
      }),

      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  methods: {
    ...mapActions({
      addNewSpecies: 'species/addNewSpecies',
      editSpecies: 'species/editSpecies',
    }),

    ...mapMutations({
      insertNewSpecies: 'species/insertNewSpecies',
      updateNewSpecies: 'species/updateNewSpecies',
    }),

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    cancelEdit() {
      this.speciesModal.title = ''
      this.speciesModal.isShowModal = false
      this.speciesModal.isEdit = false
      this.speciesForm.$reset()
    },

    async addNewSpeciesRecord() {
      this.speciesForm.$busy = true

      try {
        await this.addNewSpecies(this.speciesForm)

        this.speciesForm.$reset()
        this.speciesForm.$busy = false
        this.speciesModal.isEdit = false
        this.speciesModal.isShowModal = false

        this.showSnackbar('New specie successfully added!', 'success')
      } catch (err) {
        this.speciesForm.$busy = false

        if (err.response.status === 422) {
          this.speciesForm.$setErrors(err.response.data.errors)

          this.showSnackbar(err.response.data.message, 'error')
        }
      }
    },

    onEditSpecies(species) {
      this.speciesModal.isEdit = true
      this.speciesModal.isShowModal = true
      this.speciesModal.title = 'Update Specie'

      this.speciesForm = new Form({ ...this.speciesForm, ...species })
    },

    updateNewSpecieRecord() {
      this.speciesForm.$busy = true

      try {
        this.editSpecies(this.speciesForm.$data())

        this.speciesForm.$reset()
        this.speciesForm.$busy = false
        this.speciesModal.isEdit = false
        this.speciesModal.isShowModal = false
        this.showSnackbar('New specie successfully updated!', 'success')
      } catch (err) {
        this.speciesForm.$busy = false

        if (err.response.status === 422) {
          this.speciesForm.$setErrors(err.response.data.errors)

          this.showSnackbar(err.response.data.message, 'error')
        }
      }
    },

    showAddBreedModal() {
      this.speciesModal.isShowModal = true
      this.speciesModal.title = 'Add New Specie'
    },
  },
}
</script>
