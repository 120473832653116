<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-expansion-panels v-model="expandedSpecies" mandatory outlined>
          <v-expansion-panel
            v-for="(item, itemIndex) in species.list"
            :key="item.id"
          >
            <v-expansion-panel-header :hide-actions="false">
              <template v-slot:default="{ open }">
                <v-row class="d-flex align-center" no-gutters>
                  <v-col class="flex-grow" cols="5">
                    <h1 class="text-capitalize subtitle-1">{{ item.name }}</h1>
                  </v-col>

                  <v-col cols="3" class="pr-5">
                    <div>
                      <span class="mr-3">Breed Count:</span>
                      <span class="red--text text-right font-weight-bold">
                        {{ item.breeds_count }}
                      </span>
                    </div>
                  </v-col>

                  <v-col v-if="open" cols="4" class="d-flex">
                    <v-spacer />

                    <div>
                      <v-btn
                        @click="showRemoveSepcieDialog(item)"
                        class="text-decoration-underline mr-3"
                        color="error"
                        text
                      >
                        Delete
                      </v-btn>
                      <v-btn
                        @click="$emit('edit-species', item)"
                        class="text-decoration-underline mr-3"
                        color="info"
                        text
                      >
                        <span class="ml-2"> Edit </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:actions="{ open }">
                <template v-if="open">&nbsp;</template>
              </template>
            </v-expansion-panel-header>

            <v-divider v-if="itemIndex === expandedSpecies" />

            <v-expansion-panel-content>
              <Breeds
                :specie="item"
                :open="
                  expandedSpecies === itemIndex &&
                  item.item === selectedSpecie.id
                "
                @edit-item="editBreed"
                @add-item="addBreed"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar.show = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="editBreedModal" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5"> Edit Breed </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                v-model="breedForm.name"
                label="Breed Name"
                :error-messages="breedForm.$getError('name')"
                :hide-details="!breedForm.$hasError('name')"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4 mx-2">
          <v-spacer />

          <v-btn
            color="error"
            :disabled="breedForm.$busy"
            @click="
              editBreedModal = false
              breedForm.$reset()
            "
            text
          >
            Cancel
          </v-btn>

          <v-btn
            :loading="breedForm.$busy"
            color="primary"
            @click="editBreedRecord()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmModal v-model="confirmDeleteDialog" title="Delete">
      <template #message>
        Are you sure you want to delete
        <span v-if="selectedType.name" class="font-weight-bold red--text">
          {{
            selectedType.name.replace(/(^|\s)\S/g, function (t) {
              return t.toUpperCase()
            })
          }}
        </span>
        ?
      </template>

      <v-btn :disabled="deleting" @click="cancelRemove()" text> Cancel </v-btn>
      <v-btn :loading="deleting" @click="confirmDeleteSpecie()" text>
        Delete
      </v-btn>
    </ConfirmModal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import Breeds from '../components/Breeds'
import Form from '@/utils/form'

import { mapState, mapActions, mapMutations } from 'vuex'
import { first } from 'lodash'
import { mdiPencil, mdiPlus, mdiTrashCanOutline, mdiDelete } from '@mdi/js'

export default {
  name: 'Species',

  components: {
    Breeds,
    ConfirmModal,
  },

  data() {
    return {
      expandedSpecies: 0,
      editBreedModal: false,
      confirmDeleteDialog: false,
      deleting: false,

      icons: {
        plus: mdiPlus,
        edit: mdiPencil,
        close: mdiTrashCanOutline,
        delete: mdiDelete,
      },

      breedForm: new Form({
        id: null,
        name: null,
        species_id: null,
        created_at: null,
        updated_at: null,
      }),

      snackbar: {
        show: false,
        message: null,
        color: '',
      },

      selectedType: {
        id: null,
        name: null,
      },
    }
  },

  created() {
    this.fetchSpeciesList()
  },

  computed: {
    ...mapState({
      species: (state) => state.species,
    }),

    selectedSpecie: {
      get() {
        return this.species.list[this.expandedSpecies ?? 0]
      },
      set(newValue) {
        return newValue
      },
    },
  },

  methods: {
    ...mapActions({
      fetchSpecies: 'species/fetchSpecies',
      deleteSpecie: 'species/deleteSpecie',
      addNewBreed: 'breeds/addNewBreed',
    }),

    ...mapMutations({
      setSpeciesList: 'species/setSpeciesList',
      setListLoading: 'species/setListLoading',
      removeSpecie: 'species/removeSpecie',
      insertBreed: 'breeds/insertBreed',
      updateSpeciesCount: 'species/updateSpeciesCount',
    }),

    async fetchSpeciesList() {
      if (this.species.loading) return

      this.setListLoading(true)

      try {
        const { data } = await this.fetchSpecies()

        if (data.length) {
          this.setSpeciesList(data)
          this.selectedSpecie = first(data)
        }

        this.setListLoading(false)
      } catch (e) {
        this.setListLoading(false)
      }
    },

    editBreed(breed) {
      this.editBreedModal = true
      this.breedForm = new Form({
        ...this.breedForm,
        ...breed,
        name: breed.name.replace(/(^|\s)\S/g, function (t) {
          return t.toUpperCase()
        }),
      })
    },

    showRemoveSepcieDialog(specie) {
      this.confirmDeleteDialog = true
      this.selectedType = specie
    },

    cancelRemove() {
      this.confirmDeleteDialog = false
      this.selectedType = {
        id: null,
        name: null,
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    async addBreed(searchBreed) {
      this.breedForm = new Form({
        species_id: this.selectedSpecie.id,
        name: searchBreed.replace(/(^|\s)\S/g, function (t) {
          return t.toUpperCase()
        }),
      })

      try {
        const breed = await this.addNewBreed(this.breedForm)

        this.insertBreed(breed)
        this.updateSpeciesCount({
          id: this.breedForm.species_id,
          method: 'add',
        })

        this.breedForm.$reset()
        this.breedForm.$busy = false
        this.showSnackbar('Breed successfully added!', 'success')
      } catch (err) {
        this.breedForm.$busy = false

        if (err.response.status === 422) {
          this.breedForm.$setErrors(err.response.data.errors)
        }
        this.showSnackbar(err.response.data.message, 'error')
      }
    },

    async editBreedRecord() {
      this.breedForm.$busy = true

      try {
        const breed = await this.addNewBreed(this.breedForm)

        this.insertBreed(breed)
        this.breedForm.$reset()
        this.breedForm.$busy = false
        this.editBreedModal = false
        this.showSnackbar('Breed successfully updated!', 'success')
      } catch (err) {
        this.breedForm.$busy = false

        if (err.response.status === 422) {
          this.breedForm.$setErrors(err.response.data.errors)
        }
        this.showSnackbar(err.response.data.message, 'error')
      }
    },

    async confirmDeleteSpecie() {
      this.deleting = true

      try {
        await this.deleteSpecie(this.selectedType)

        this.confirmDeleteDialog = false
        this.deleting = false

        this.showSnackbar('Species successfully deleted!', 'success')
      } catch (err) {
        this.confirmDeleteDialog = false
        this.deleting = false

        if (err.response.status === 422) {
          this.breedForm.$setErrors(err.response.data.errors)
        }

        this.showSnackbar(err.response.data.message, 'error')
      }
    },
  },
}
</script>
